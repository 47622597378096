import cx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { type PropsWithChildren, useMemo } from 'react';
import { LogoSvg } from '../../assets/new';
import { cn } from '../../utils/cn';
import { GreenButtonLink } from '../button/green-button';
import { GreenTransparentButtonLink } from '../button/green-transparent-button';
import { Socials } from '../social';
import { SimpleFooter } from './simpleFooter.component';

export interface NavFooterProps {
  className?: string;
}

const description =
  'The Population Project relies heavily on the work and contributions of  volunteers. We believe that information-gathering and use should go  hand-in-hand with transparency. This Privacy Policy explains how the  Population Project, the non-profit organization that hosts this site,  collects, uses, and shares information we receive from you through your  use of the Population Project Site. It is essential to understand that,  by using the Population Project Site, you consent to the collection,  transfer, processing, storage, disclosure, and use of your information  as described in this Privacy Policy. That means that reading this Policy carefully is important. \n\n' +
  "We believe that you shouldn't have to  provide nonpublic Personal Information to participate to the Population  Project. You do not have to provide things like your real name, address, or country to sign up for a standard account or contribute content to  the Population Project Site. \n\n" +
  'We do not sell or rent your Personal Information, nor do we give it to  others to sell you anything. We use it to figure out how to make the  Population Project Site more engaging and accessible. Put simply: we use this information to make the Population Project Site better for you.';

export function NavFooter({ className }: NavFooterProps) {
  const router = useRouter();

  const showSimpleFooter = useMemo(() => {
    if (
      router.pathname === '/sign-in' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/set-new-password' ||
      router.pathname === '/continue-as-guest' ||
      router.pathname === '/forgot-password'
    ) {
      return true;
    }
    return false;
  }, [router.pathname]);

  if (showSimpleFooter) {
    return <SimpleFooterHTML className={className} />;
  }

  return <FooterHTML className={className} />;
}

function FooterHTML({ className }: NavFooterProps) {
  const { t } = useTranslation(['new-footer']);

  return (
    <footer className={`bg-[#FAFAFA] ${cx(className)}`}>
      <div className="container mx-auto pb-10 pt-[3.75rem] max-lg:py-10">
        <div className="flex justify-between space-x-6 max-lg:flex-col max-lg:space-x-0">
          <div className="flex max-w-80 flex-col items-start">
            <LogoSvg width="8.5rem" height="3rem" className="" />
            <div className="mt-4 text-label-2-regular text-neutral-600">
              {t('a-nonprofit-organisation-striving-footer')}
            </div>

            <GreenButtonLink
              className="mt-4"
              payload="Get Started"
              href="/sign-up"
            />
          </div>

          <div className="grid grid-flow-col gap-6 max-lg:mt-6 max-lg:grid-flow-row max-lg:grid-cols-2 max-lg:gap-x-4 max-lg:gap-y-6">
            {/* <Block title="Project" className=""> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Course" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Pricing" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Testimonials" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="FAQ" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Support" /> */}
            {/* </Block> */}
            {/* <Block title="Help" className=""> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="About us" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Universities" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Contacts" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Privacy policy" /> */}
            {/*  <GreenTransparentButtonLink */}
            {/*    href={'/'} */}
            {/*    payload="Terms & Conditions" */}
            {/*  /> */}
            {/* </Block> */}
            {/* <Block title="Resources"> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Pushback" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="On able" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Anyway" /> */}
            {/*  <GreenTransparentButtonLink href={'/'} payload="Exploratory" /> */}
            {/*  <GreenTransparentButtonLink */}
            {/*    href={'/'} */}
            {/*    payload="Tentative create" */}
            {/*  /> */}
            {/* </Block> */}

            <Block title="Project" className="">
              <GreenTransparentButtonLink href={'/about'} payload="About us" />
              <GreenTransparentButtonLink
                href={'/methodology'}
                payload="Methodology"
              />
              <GreenTransparentButtonLink
                href={'/privacy'}
                payload="Privacy Policy"
              />
              <GreenTransparentButtonLink href={'/blog'} payload="Blog" />
              <GreenTransparentButtonLink
                href={'/founders-thoughts'}
                payload="Founder's thoughts"
              />
            </Block>

            <Block title="Contributing" className="">
              <GreenTransparentButtonLink
                href={'/guides/volunteering'}
                payload="Help us"
              />
              <GreenTransparentButtonLink
                href={'/volunteer/submit'}
                payload="Submit a list"
              />
              <GreenTransparentButtonLink
                href={'/create-human'}
                payload="Create a record"
              />
            </Block>

            <Block title="Socials" className="min-w-0">
              <Socials />
            </Block>
          </div>
        </div>
        <div className="mt-[3.75rem] whitespace-pre-line text-xs text-neutral-500">
          {description}
        </div>
      </div>
    </footer>
  );
}

function SimpleFooterHTML({ className }: NavFooterProps) {
  return (
    <footer
      className={`${cx(
        className
      )} hidden w-full items-center justify-center text-gray-400 md:flex`}
    >
      <SimpleFooter />
    </footer>
  );
}

function Block({
  title,
  children,
  className,
}: PropsWithChildren<{ title: string; className?: string }>) {
  return (
    <div className={cn('min-w-48 max-lg:min-w-0', className)}>
      <div className="text-label-2-semibold text-neutral-600 ">{title}</div>
      <div className="mt-4 flex flex-col items-start space-y-3">
        {' '}
        {children}
      </div>
    </div>
  );
}
