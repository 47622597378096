import type { IconType } from 'react-icons';
import {
  InstagramIcon,
  XSocialIcon,
  LinkedinIcon,
  FacebookIcon,
} from 'src/assets/new/icons';
import { GreenTransparentButtonLink } from '../button/green-transparent-button';
import type { SocialType } from './socialIconLink.component';
import SocialLinks from './socialLinks.json';

export function Socials() {
  const icons: Record<SocialType, IconType> = {
    instagram: InstagramIcon,
    twitter: XSocialIcon,
    linkedin: LinkedinIcon,
    facebook: FacebookIcon,
  };

  return (
    <div className="flex flex-row space-x-3">
      {SocialLinks.map(({ type, url }) => {
        const Icon = icons[type as SocialType];

        return (
          <GreenTransparentButtonLink
            className="p-0"
            key={type}
            href={url}
            IconBefore={<Icon width="1.5rem" height="1.5rem" />}
          />
        );
      })}
    </div>
  );
}
